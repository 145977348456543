<template>
  <fragment>
    <div class="layer_body" style="overflow: visible">
      <comm-inp-search
        ref="input"
        :method="'post'"
        :url="'/api/common/selectEmployeeList'"
        :initValue="commInpSearchInitValue"
        :isDisplayResult="true"
        :isSearchByInitValue="false"
        :displayData="displaySearchResult"
        :inputRef="'keyword'"
        @select="selectEmp"
        @init="&quot;&quot;;"
      />
    </div>
    <div class="layer_foot">
      <div class="wrap_btn">
        <slot />
        <!--X(창닫기)-->
        <button type="button" class="btn_medium btn_primary" @click.prevent="login">로그인</button>
      </div>
    </div>
  </fragment>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { Fragment } from "vue-fragment";

export default {
  name: "CommBackdoor",
  components: {
    CommInpSearch,
    Fragment,
  },
  mixins: [CommLayerMixin],
  computed: {
    ...mapGetters("CommStore", {
      layer: "getFirstLayer",
    }),
  },
  data() {
    return {
      emp: {},
      commInpSearchInitValue: "",
    };
  },
  mounted() {
    this.$refs.input.$refs.keyword.focus();
  },
  methods: {
    displaySearchResult(dataList) {
      dataList.forEach((value) => {
        value.display = value.empName + "(" + value.accountId + ")";
      });
    },
    selectEmp(emp) {
      this.emp = emp;
    },
    login() {
      // console.log(this.emp)
      axios
        .get("/api/backdoorLogin", {
          params: {
            accountId: this.emp.accountId,
          },
        })
        .then((res) => {
          this.$store.commit("CommStore/login", res.data);
          this.closeLayer();
          // window.localStorage.vuex = null
          location.reload("/");
        });
    },
    closeLayer() {
      this._closeLayer(this.layer.order);
      this.$parent.$destroy();
    },
  },
};
</script>
